import React, {useState} from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import {
  Section,
  SectionTitle,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import LikeProductCarousel from "../components/LikeProductCarousel"
import QuotePopup from "../components/QuotePopup"
import CallNow from "../components/CallNow"
import BreakpointUp from "../components/Media/BreakpointUp"
import PrimaryForm from "../components/PrimaryForm"
import { StaticImage } from "gatsby-plugin-image"
import ListArrow from "../images/BulletIcon.svg"
import LocationStyle from "../components/LocationStyle"
import MapStyle from "../components/MapStyle/Cities/RvCarport"

const SectionOverlayBg = styled.div`
  height: 100%;
  width: 30%;
  position: absolute;
  top: 0px;
  right: 0;
  &:after {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    top: 0;
    width: 90%;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`
const SectionOverlay = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SectionOverlayContent = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 855px;
  & p {
    margin-bottom: 50px;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 38px;
    }
    & strong {
      color: #000;
      font-weight: 700;
      line-height: 24px;
    }
  }
  & small {
    display: block;
    color: #0b619b;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }

  & ul {
    list-style: none;
    margin: 0 -5px;
    padding: 0;
    & li {
      display: inline-block;
      padding: 0 5px;
      & a {
        color: #000;
        font-weight: 700;
        text-decoration: underline;
        &:hover {
          color: #0b619b;
          text-decoration: none;
        }
      }
    }
  }
`
const LeftContent = styled.div`
  & p {
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 40px;
    }
    > a{
      &:hover{
        text-decoration:underline;
      }
    }
  }
`
const SectionEditor = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-right: -15px;
  margin-left: -15px;
  ${BreakpointUp.lg`        
        margin-right: -30px;
        margin-left: -30px; 
    `}
  ul {
    font-weight: 700;
    li {
      padding-left: 40px;
      vertical-align: middle;
      margin: 0;
      line-height: 26px;
      position: relative;
      + li {
        margin-top: 15px;
      }
      &:before {
        content: url(${ListArrow});
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-shadow: 0 0 0px 6px rgba(23, 27, 39, 0.04);
        vertical-align: middle;
        background: #fff;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 3px;
      }
    }
  }
  &.section-editor {
    + .section-editor {
      border-top: 1px solid #dde4f0;
      padding-top: 70px;
      margin-top: 70px;
    }
  }
`
const SectionEditorLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`        
        padding-right: 30px;
        padding-left: 30px; 
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & p {
    ${BreakpointUp.lg`
            font-size:20px;
            line-height:38px;
        `}
  }
`
const SectionEditorRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`        
        padding-right: 30px;
        padding-left: 30px; 
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & p {
    & small {
      font-size: 20px;
      line-height: 38px;
      display: block;
      font-weight: 700;
      color: #999;
    }
  }
  & strong {
    display: block;
    font-weight: 700;
    color: #000;
    line-height: 28px;
  }
`

const City = ({ data, location, pageContext: { category, city } }) => {
  const [isVisibleQuote, setQuoteVisible] = useState(false);

  const pageData = data.contentfulState
  const cityPageData = city  
  const productCategory = category
  const relatedProductsData = []
  
  data.allContentfulProduct.edges.map((product) => {
    if (product.node.productCategory.name.toLowerCase() === productCategory.split("-").join(" ")) {
      relatedProductsData.push(product.node)
    } else {
      product.node.productSubCategories.map((item) => {
        if(item.name.toLowerCase() === productCategory.split("-").join(" ")) {
          relatedProductsData.push(product.node)
        }
      })
    }
  })


  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  
  return (
    <Layout location={location}>
      <Seo
        title={`RV Carports ${city} ${pageData.abbreviation} | Metal RV Covers ${city}| Prefab RV Carport Kits ${city}, ${pageData.name}`}
        description={`Buy RV Carports in ${city} ${pageData.abbreviation}. Our prefab metal RV covers offer the best protection to your recreational vehicle against rain, snow and UV radiation in ${city} ${pageData.name}.`}
      />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <Link
            to={`/metal-rv-covers-${pageData.name
              .split(" ")
              .join("-")
              .toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
          >
            {" "}
            {pageData.name} /{" "}
          </Link>
          <span>{city}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="90px"
        xpt="120px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left top-0"
      >
        <div className="container">
          <MainPanel alignItems="center">
            <LeftPannel>
              <LeftContent>
                <h1>{`Metal RV Covers in ${city}`}</h1>
                <p>
                  Your luxury Recreational Vehicle must be expensive and so it’s
                  necessary to save and protect it. Our Metal RV Cover at Coast
                  to Coast Carports, Inc. can provide you a sheltered space to
                  keep your valuable RV safe with you for longer. We use only
                  galvanized steel to make sturdy constructions that are tensile
                  and durable. You can choose your RV cover in any size and
                  customize it your way to match your style!
                </p>
                <p>
                Coast to Coast Carports provides a wide range of strong and durable RV carports in {city}, which includes regular roof, a-frame roof, and vertical roof carports. These steel carports are capable of withstanding severe weather conditions, ensuring your recreational vehicles, such as RVs, motorhomes, trailers, fifth-wheels, and others, remain safe and protected. Our steel carports are also resistant to moisture and pests, which means your vehicles will remain secure and safeguarded for many years to come. Moreover, in addition to RV storage, these carports are multi-purpose and perfect for commercial, residential, agricultural, and industrial uses. If you require a secure storage solution for your valuables, call our building specialists at <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a> to customize and design the ideal structure for your needs.
                </p>
              </LeftContent>
            </LeftPannel>
            <RightPannel>
              <div className="isSticky">
                <PrimaryForm states={data.allContentfulState.edges}  location = {location} />
              </div>
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
      <Section pt="0" pb="90px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <MapStyle data = {pageData} cityData={cityPageData}  />
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle mb="30px">Explore {city}’s RV Covers</SectionTitle>
        </div>
        <LikeProductCarousel 
        relatedProductsData={relatedProductsData} 
        openQuoteModal={() => setQuoteVisible(true)} 
        />
      </Section>
      {(pageData.abbreviation === "ID" || pageData.abbreviation === "TX" || pageData.abbreviation === "TN" || pageData.abbreviation === "OR" ) &&  
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#DDE4F0">
          <div className="container">
            <LocationStyle city={cityPageData}  />
          </div>
        </Section>
      }
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionEditor className="section-editor">
            <SectionEditorLeft>
              <h2>Roof Styles for Metal RV Covers</h2>
              <p>
                One of the first places the sun will affect on your RV, and one
                of the last places you think to check, is the roof. While these
                damages can be difficult to fix, you can avoid it all by
                purchasing one of our steel RV covers. And you can top it off
                with one of our roof styles at Coast to Coast Carports: regular,
                A-frame, or vertical.
              </p>
            </SectionEditorLeft>
            <SectionEditorRight>
              <ul>
                <li>
                  <h3>Regular (Good)</h3>
                  For customers who do not experience high winds, heavy rainfall
                  or snowfall, we recommend our regular roof for your metal
                  building. This roof is our most economical option, designed
                  with rounded edges and horizontally-oriented paneling.
                </li>
                <li>
                  <h3>A-frame (Better)</h3>Similar to the regular roof, our
                  A-frame style is also installed with horizontal panels.
                  However, it is best suited for areas with some high winds, but
                  very light snow and rainfall. Some people also refer to this
                  roof as a boxed-eave or A-frame horizontal design.
                </li>
                <li>
                  <h3>Vertical (Best)</h3> Our vertical roof is the top-selling,
                  most durable design offered at Coast to Coast. It is installed
                  with vertical panels which can easily channel all rain, snow,
                  and other debris off the roof. We recommend this roof style to
                  customers who live in areas with foul weather and for
                  structures that have a length of 36’ or longer.
                </li>
              </ul>
            </SectionEditorRight>
          </SectionEditor>
          <SectionEditor className="section-editor">
            <SectionEditorLeft>
              <h2>
                Site Prep and Installation in {city}, {pageData.name}{" "}
              </h2>
              <p>
                Before we can install your steel RV cover, there are a few
                things that you (the customer) must complete.
              </p>
            </SectionEditorLeft>
            <SectionEditorRight>
              <ul>
                <li>
                  1. You need to have a level space for your steel building to
                  be installed on
                </li>
                <li>
                  2. Check with your local ordinances to see if there are local
                  buildings codes to be met and if there are required permits to
                  obtain.
                </li>
                <li>
                  3. Will your structure need to be certified? Depending on your
                  location and the weather, you may or may not have to certify
                  your RV carport. Certified structures are designed and
                  engineered to meet local wind and snow ratings.
                </li>
                <li>
                  4. Clear the designated site of all debris and vegetation that
                  might delay the install.
                </li>
              </ul>
            </SectionEditorRight>
          </SectionEditor>
        </div>
      </Section>
      <Section
        pt="90px"
        pb="90px"
        xpt="60px"
        xpb="60px"
        bgColor="#fff"
        className="circle-right"
      >
        <div className="container mw-1120">
          <MarkdownContent>
            <h2>Steel RV Carport {city} Prices</h2>
            <p>
              It’s easy to find a dealer nowadays who offers various sales and
              discounts, all with a compromise of quality. Here at Coast to
              Coast, we understand when you purchase an RV or camper, you’ve
              already spent a significant amount of money, So, we promise to do
              everything we can to find the perfect RV shelter at a price you
              can afford. The cost of your motorhome shelter will depend on
              several factors, including its dimensions – width, length, and
              height. You also need to consider what your local building codes
              are; will you be required to have a permit? Are there certain wind
              and snow ratings that must be met? It may seem overwhelming at
              first, but that’s what we’re her for. When it comes to the prices
              of our products, we offer two excellent financing and rent-to-own
              options to provide additional financial assistance to those who
              need it. For more information regarding these services, you can
              contact one of our buildings specialists or visit our website.
            </p>

            <h2>Advantages of Metal RV Covers in {city}</h2>
            <p>
              Owning a motorhome can be very exciting, especially when you plan
              to travel often. But when you aren’t on the road, where do you
              plan to keep your new investment? A steel RV cover in {city},{" "}
              {pageData.abbreviation} will keep your home-on-the-road protected
              from the elements year-round. And if you’re wondering what makes
              our metal structures better than others, you can check out some of
              their benefits below:
            </p>
            <ul>
              <li>Reliable</li>
              <li>Eco-Friendly</li>
              <li>Cost-Effective</li>
              <li>Rust, Pest, and Fire Resistant</li>
              <li>100% American-made Galvanized Steel</li>
              <li>Long-lasting</li>
              <li>Convenient</li>
              <li>Fully-Customizable</li>
            </ul>
            <h2>Add a Pop of Color</h2>
            <p>
              Choosing the perfect set of colors for your metal building can be
              quite difficult when you aren’t sure what the final product will
              look like. But with Coast to Coast Carports’ 3D Color Planner,
              it’s never been easier! You can pick your ideal color combination
              for the roof, trim, and walls from the comfort of your own couch.
              It really is that easy. Just sit back, relax, and watch your dream
              structure come to life on your computer screen. We also offer a 3D
              Building Estimator to help you design your dream structure from
              the ground up.
            </p>
          </MarkdownContent>
        </div>
      </Section>
      <Section
        pt="140px"
        pb="140px"
        xpt="60px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left"
      >
        <SectionOverlayBg>
          <StaticImage src="../images/purchase-bg.jpg" alt="purchase" />
        </SectionOverlayBg>
        <div className="container">
          <SectionOverlay>
            <SectionOverlayContent>
              <h2>RV Covers at Coast to Coast Carports</h2>
              <p>
                We’ve been providing metal building solutions to customers in
                the {city} area since 2001, meeting all their residential,
                commercial, and agricultural needs. The dedication we have for
                our customers is what sets us apart from other companies in the
                metal building industry. We strive to provide excellent customer
                service and quality products for affordable prices. Don’t wait
                any longer to purchase your dream RV shelter, call us today at{" "}
                <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a> to speak with a
                friendly building specialist.
              </p>
              <small>ALSO Explore :</small>
              <ul>
                <li>
                  <Link
                    to={`/metal-carports-${city.replace(/[^a-zA-Z0-9 ]/g, '').split(" ").join("-").toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
                  >
                    Metal Carports {city} {pageData.abbreviation}
                  </Link>
                  ,
                </li>
                <li>
                  <Link
                    to={`/metal-garages-${city.replace(/[^a-zA-Z0-9 ]/g, '').split(" ").join("-").toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
                  >
                    Metal Garages {city} {pageData.abbreviation}
                  </Link>
                  ,
                </li>
                <li>
                  <Link
                    to={`/metal-buildings-${city.replace(/[^a-zA-Z0-9 ]/g, '').split(" ").join("-").toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
                  >
                    Metal Buildings {city} {pageData.abbreviation}
                  </Link>
                </li>
              </ul>
            </SectionOverlayContent>
          </SectionOverlay>
        </div>
      </Section>
      <CallNow />
      <QuotePopup
        isVisible={isVisibleQuote}
        location={location}
        onClose={() => setQuoteVisible(false)}
      />
    </Layout>
  )
}

export default City

export const pageQuery = graphql`
  query MetalRVCityLandingQuery($id: String!) {
    contentfulState(id: { eq: $id }) {
      name
      abbreviation
      locationForCities {
        cityName
        rvCarportAddress
        rvCarportContactNumber
        rvCarportLocationLink
        rvCarportMapEmbedLink {
          rvCarportMapEmbedLink
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          locationForCities {
            cityName
            rvCarportAddress
            rvCarportContactNumber
            rvCarportLocationLink
            rvCarportMapEmbedLink {
              rvCarportMapEmbedLink
            }
          }
        }
      }
    }
    allContentfulProduct(
      filter: {
        productSubCategories: { elemMatch: { name: { eq: "RV Carports" } } }
      }
    ) {
      edges {
        node {
          productName
          productSku
          productName
          url
          productImages {
            title
            gatsbyImageData
          }
          productCategory {
            name
          }
          productSubCategories {
            name
          }
          width
          height
          length
          price
        }
      }
    }
  }
`
